// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/react-image-gallery/styles/css/image-gallery.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Spectral', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Navbar ul li a {
	font-size: 18px;
}

.Navbar ul li a:hover {
	text-decoration: none;
	opacity: 0.7;
}

.slider img {
	width: 100%;
}

.slider h2 {
	font-size: 60px;
    font-style: italic;
    margin-bottom: 50px!important;
    margin-top: 20px;
}

h3 {
	margin-bottom: 20px;
}

p {
	font-size: 18px;
}

.btn-primary {
	background-color: black;
	border: 1px solid black;
}

.btn-primary:hover {
	opacity: 0.7;
	background-color: black;
	border: 1px solid black;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
	color: white;
	height: 70px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,8BAA8B;EAC9B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,qBAAqB;CACrB,YAAY;AACb;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,eAAe;IACZ,kBAAkB;IAClB,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,uBAAuB;CACvB,uBAAuB;AACxB;;AAEA;CACC,YAAY;CACZ,uBAAuB;CACvB,uBAAuB;AACxB;;AAEA;CACC,YAAY;CACZ,YAAY;AACb","sourcesContent":["@import \"~react-image-gallery/styles/css/image-gallery.css\";\n\nbody {\n  margin: 0;\n  font-family: 'Spectral', serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.Navbar ul li a {\n\tfont-size: 18px;\n}\n\n.Navbar ul li a:hover {\n\ttext-decoration: none;\n\topacity: 0.7;\n}\n\n.slider img {\n\twidth: 100%;\n}\n\n.slider h2 {\n\tfont-size: 60px;\n    font-style: italic;\n    margin-bottom: 50px!important;\n    margin-top: 20px;\n}\n\nh3 {\n\tmargin-bottom: 20px;\n}\n\np {\n\tfont-size: 18px;\n}\n\n.btn-primary {\n\tbackground-color: black;\n\tborder: 1px solid black;\n}\n\n.btn-primary:hover {\n\topacity: 0.7;\n\tbackground-color: black;\n\tborder: 1px solid black;\n}\n\n.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {\n\tcolor: white;\n\theight: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
