import React from 'react'
import Gallery from './Gallery';
import { MapPin } from 'lucide-react';

const Place = () => {
	return (
		<div className="Place mt-5">
			<div className="row d-flex justify-content-center">
				<div className="col-md-6">
					<h3 className="d-flex align-items-center"><MapPin className="mr-1" />Lugar de celebración</h3>
					<p>HOTEL MELIÁ VILLAITANA, Benidorm</p>
					<div className="mb-4">
						<Gallery />
					</div>
					<div>
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3119.926011040364!2d-0.14986812413364584!3d38.55851846662854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6204b256171e13%3A0xaf04922fa578b95b!2sMeli%C3%A1%20Villaitana!5e0!3m2!1ses!2ses!4v1699376162106!5m2!1ses!2ses" height="450" className="border-0 w-100" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Maps"></iframe>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Place
