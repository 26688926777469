import React from 'react'
import Boda from "../assets/img/boda-hor.jpeg";
import { CalendarDays } from 'lucide-react';

const Icos = () => { 
	return (
		<div className="Icos mt-5">
			<div className="row d-flex justify-content-center">
				<div className="col-md-6">
					<h3 className="d-flex align-items-center"><CalendarDays className="mr-1" /> Día de la boda</h3>
					<img src={Boda} className="w-100" alt="Boda" />
				</div>
			</div>
		</div>
	)
}

export default Icos
