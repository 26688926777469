import ImageGallery from "react-image-gallery";
import Uno from "../assets/img/1.jpg";
import Dos from "../assets/img/2.jpg";
import Tres from "../assets/img/3.jpg";
import Cuatro from "../assets/img/4.jpeg";
import Cinco from "../assets/img/5.jpg";
import Seis from "../assets/img/6.jpg";
import Siete from "../assets/img/7.jpg";
const images = [
	{
	  original: Uno,
	},
	{
	  original: Dos,
	},
	{
	  original: Tres,
	},
	{
		original: Cuatro,
	},
	{
		original: Cinco,
	},
	{
		original: Seis,
	},
	{
		original: Siete,
	}
  ];

const Gallery = () => {
	return (
		<ImageGallery 
			items={images} 
			showPlayButton={false}
			showThumbnails={false}
			showFullscreenButton={false}
		/>
	)
}

export default Gallery
