import React from 'react'
import { GiftIcon } from 'lucide-react'

const Gift = () => {
	return (
		<div className="Gift mt-5">
			<div className="row d-flex justify-content-center">
				<div className="col-md-6">
					<h3 className="d-flex align-items-center"><GiftIcon className="mr-1" /> Regalo</h3>
					<p>El mejor regalo que nos puedes hacer es acompañarnos en este día tan especial para nosotros. Consideramos que no hay nada más bonito que poder compartir este momento con nuestra familia y amigos.</p>
					<p>Aún así, entendemos que algunos invitados querrán tener un detalle con nosotros. En este caso, pueden hacerlo a través de nuestro número de cuenta: ES14 0182 1140 3502 0166 6878.</p>
				</div>
			</div>
		</div>
	)
}

export default Gift
