import React from 'react'
import Logo from "../assets/img/logo.png";
import Countdown from './Countdown';

const Slider = () => {
	return (
		<div className="slider mt-5">
			<div className="row d-flex justify-content-center">
				<div className="col-md-6">
					<h2 className="text-center mb-3">Boda Paula & Juan</h2>
					<Countdown />
					<img src={Logo} alt="Logo boda" />
				</div>
			</div>
			
		</div>
	)
}

export default Slider
