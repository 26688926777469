import React from 'react'

const Footer = () => {
	return (
		<div className="Gift mt-3 mb-3">
			<div className="row d-flex justify-content-center">
				<div className="col-md-6">
					<hr />
					<div className="d-flex justify-content-between">
						<span><i>Boda de Paula y Juan</i></span>
						<span>11 de mayo 2024</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
