import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
	return (
		<div className="Navbar mt-5">
			<ul className="d-flex list-unstyled justify-content-center">
				<li className="mr-5"><Link to="#Form" className="text-dark font-weight-bold">Portada</Link></li>
				<li className="mr-5"><a href="./#formulario" className="text-dark font-weight-bold">Asistencia</a></li>
				<li><a href="./#booking" className="text-dark font-weight-bold">Alojamiento</a></li>
			</ul>
		</div>
	)
}

export default Navbar
