import { BedDouble } from "lucide-react"

const Booking = () => {
	return (
		<div className="Form mt-5" id="booking">
			<div className="row d-flex justify-content-center">
				<div className="col-md-6">
					<h3 className="d-flex align-items-center"><BedDouble className="mr-1" />Alojamiento</h3>
					<p>Si lo deseas, el día de la boda podrás alojarte en el hotel donde tendrá lugar la celebración. Puedes hacer tu reserva desde <a href="https://events.melia.com/es/events/melia-villaitana/Enlace-Paula-Y-Juan" target="_blank" rel="noreferrer" className="text-dark underline font-weight-bold"><u>este enlace</u></a>.</p> 
					<p>Aunque el hotel está a las afueras de Benidorm, se encuentra a tan solo 10 minutos en coche del centro, donde también puedes encontrar multitud de opciones de alojamiento. Si necesitas ayuda o recomendación para encontrar tu hotel, ¡no dudes en preguntarnos!</p>
					<p>Además, también tendrás a tu disposición un autobús de ida y vuelta desde Elda hasta el hotel. Para reservar tu plaza, solo tienes que avisarnos. :) </p>
				</div>
			</div>
		</div>
	)
} 

export default Booking
