import { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import { Send } from 'lucide-react';


const Formulario = () => {

	const form = useRef();

	const [ exito, setExito ] = useState(false);
	const [ error, setError ] = useState(false);

	const [formData, setFormData] = useState({
        name: '',
        email: '',
		acompanante: '-',
		bus: '-',
		hotel: '-',
		moreInfo: '',
		acompanantesNumber: 0,
		acompananteUnoName: '',
		acompananteUnoRelation: '',
		acompananteDosName: '',
		acompananteDosRelation: '',
		acompananteTresName: '',
		acompananteTresRelation: '',
		acompananteCuatroName: '',
		acompananteCuatroRelation: ''
	});

	const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
	};

    const sendEmail = (e) => {
		setExito(false);
		e.preventDefault();
		
		emailjs.sendForm('service_8qk14lr', 'template_i6icmw4', form.current, process.env.REACT_APP_PASS_EMAIL)
		.then((result) => {
			if (result.text === 'OK') {
				setError(false);
				setExito(true);
				setFormData({
					name: '',
					email: '',
					acompanante: '-',
					bus: '-',
					hotel: '-',
					moreInfo: '',
					acompanantesNumber: 0,
					acompananteUnoName: '',
					acompananteUnoRelation: '',
					acompananteDosName: '',
					acompananteDosRelation: '',
					acompananteTresName: '',
					acompananteTresRelation: '',
					acompananteCuatroName: '',
					acompananteCuatroRelation: ''
				});
			}
		}, (error) => {
			console.log(error);
			setExito(false);
			setError(true);
		});
    }; 
	
	return (
		<div className="Form mt-5" id="formulario">
			<div className="row d-flex justify-content-center">
				<div className="col-md-6">
					<h3 className="d-flex align-items-center"><Send  className="mr-1" />Confirma tu asistencia</h3>
					<form ref={form} onSubmit={sendEmail}>
						<div className="form-group">
							<label htmlFor="name">Nombre</label>
							<input 
								type="text" 
								className="form-control" 
								name="name"
								id="name" 
								placeholder="Introduce aquí tu nombre" 
								value={formData.name}
								onChange={handleChange}
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="email">Correo electrónico</label>
							<input 
								type="email" 
								className="form-control" 
								name="email"
								id="email" 
								placeholder="Introduce tu email" 
								value={formData.email}
								onChange={handleChange}
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="acompanante">¿Vienes con acompañante?</label>
							<select 
								className="form-control" 
								id="acompanante"
								name="acompanante"
								value={formData.acompanante}
        						onChange={handleChange}
							>
								<option>-</option>
								<option>Si</option>
								<option>No</option>
							</select>
						</div>
						{formData.acompanante === 'Si' && 
							<div className="form-group">
								<label htmlFor="acompanantesNumber">¿Con cuántos acompañantes vienes?</label>
								<input 
									type="number" 
									className="form-control" 
									name="acompanantesNumber"
									id="acompanantesNumber" 
									value={formData.acompanantesNumber}
									onChange={handleChange}
									max="4"
								/>
							</div>
						}
						{formData.acompanantesNumber === "1" && 
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteUnoName">Nombre y apellidos del acompañante 1</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteUnoName"
										id="acompananteUnoName" 
										value={formData.acompananteUnoName}
										onChange={handleChange}
									/>
								</div>
									<div className="form-group">
									<label htmlFor="acompananteUnoRelation">¿Cuál es tu relación con el acompañante 1?</label>
									<select 
										className="form-control" 
										id="acompananteUnoRelation"
										name="acompananteUnoRelation"
										value={formData.acompananteUnoRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
							</div>
						}
						{formData.acompanantesNumber === "2" && 
						<>
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteUnoName">Nombre y apellidos del acompañante 1</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteUnoName"
										id="acompananteUnoName" 
										value={formData.acompananteUnoName}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="acompananteUnoRelation">¿Cuál es tu relación con el acompañante 1?</label>
									<select 
										className="form-control" 
										id="acompananteUnoRelation"
										name="acompananteUnoRelation"
										value={formData.acompananteUnoRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
							</div>
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteDosName">Nombre y apellidos del acompañante 2</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteDosName"
										id="acompananteDosName" 
										value={formData.acompananteDosName}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="acompananteDosRelation">¿Cuál es tu relación con el acompañante 2?</label>
									<select 
										className="form-control" 
										id="acompananteDosRelation"
										name="acompananteDosRelation"
										value={formData.acompananteDosRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
								</div>
							</>
						}
						{formData.acompanantesNumber === "3" && 
							<>
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteUnoName">Nombre y apellidos del acompañante 1</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteUnoName"
										id="acompananteUnoName" 
										value={formData.acompananteUnoName}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="acompananteUnoRelation">¿Cuál es tu relación con el acompañante 1?</label>
									<select 
										className="form-control" 
										id="acompananteUnoRelation"
										name="acompananteUnoRelation"
										value={formData.acompananteUnoRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
							</div>
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteDosName">Nombre y apellidos del acompañante 2</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteDosName"
										id="acompananteDosName" 
										value={formData.acompananteDosName}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="acompananteDosRelation">¿Cuál es tu relación con el acompañante 2?</label>
									<select 
										className="form-control" 
										id="acompananteDosRelation"
										name="acompananteDosRelation"
										value={formData.acompananteDosRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
							</div>
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteTresName">Nombre y apellidos del acompañante 3</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteTresName"
										id="acompananteTresName" 
										value={formData.acompananteTresName}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="acompananteTresRelation">¿Cuál es tu relación con el acompañante 3?</label>
									<select 
										className="form-control" 
										id="acompananteTresRelation"
										name="acompananteTresRelation"
										value={formData.acompananteTresRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
							</div>
							</>
						}
						{formData.acompanantesNumber === "4" && 
							<>
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteUnoName">Nombre y apellidos del acompañante 1</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteUnoName"
										id="acompananteUnoName" 
										value={formData.acompananteUnoName}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="acompananteUnoRelation">¿Cuál es tu relación con el acompañante 1?</label>
									<select 
										className="form-control" 
										id="acompananteUnoRelation"
										name="acompananteUnoRelation"
										value={formData.acompananteUnoRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
							</div>
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteDosName">Nombre y apellidos del acompañante 2</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteDosName"
										id="acompananteDosName" 
										value={formData.acompananteDosName}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="acompananteDosRelation">¿Cuál es tu relación con el acompañante 2?</label>
									<select 
										className="form-control" 
										id="acompananteDosRelation"
										name="acompananteDosRelation"
										value={formData.acompananteDosRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
							</div>
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteTresName">Nombre y apellidos del acompañante 3</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteTresName"
										id="acompananteTresName" 
										value={formData.acompananteTresName}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="acompananteTresRelation">¿Cuál es tu relación con el acompañante 3?</label>
									<select 
										className="form-control" 
										id="acompananteTresRelation"
										name="acompananteTresRelation"
										value={formData.acompananteTresRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
							</div>
							<div className="bg-light p-2 border mb-3">
								<div className="form-group">
									<label htmlFor="acompananteCuatroName">Nombre y apellidos del acompañante 4</label>
									<input 
										type="text" 
										className="form-control" 
										name="acompananteCuatroName"
										id="acompananteCuatroName" 
										value={formData.acompananteCuatroName}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="acompananteCuatroRelation">¿Cuál es tu relación con el acompañante 4?</label>
									<select 
										className="form-control" 
										id="acompananteCuatroRelation"
										name="acompananteCuatroRelation"
										value={formData.acompananteCuatroRelation}
										onChange={handleChange}
									>
										<option>-</option>
										<option>Es mi hijo/a</option>
										<option>Es mi pareja</option>
										<option>Otro</option>
									</select>
								</div>
							</div>
							</>
						}
						<div className="form-group">
							<label htmlFor="bus">¿Vas a necesitar autobús Elda - Meliá Villatiana?</label>
							<select 
								className="form-control" 
								id="bus"
								name="bus"
								value={formData.bus}
        						onChange={handleChange}
							>
								<option>-</option>
								<option>Si</option>
								<option>No</option>
								<option>Todavía no lo se</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="hotel">¿Quieres alojarte en el hotel?</label>
							<select 
								className="form-control" 
								id="hotel"
								name="hotel"
								value={formData.hotel}
        						onChange={handleChange}>
								<option>-</option>
								<option>Si</option>
								<option>No</option>
								<option>Todavía no lo se</option>
							</select>
						</div>
						{(formData.hotel === "Si" || formData.hotel === "Todavía no lo se")  && 
						<div className="alert alert-primary" role="alert">
							Puedes reservar a un precio con descuento desde este enlace <a href="https://events.melia.com/es/events/melia-villaitana/Enlace-Paula-Y-Juan" target="_blank" rel="noreferrer">https://events.melia.com/es/events/melia-villaitana/Enlace-Paula-Y-Juan</a> 
					 	</div>
						}
						<div className="form-group">
							<label htmlFor="moreInfo">Déjanos aquí todo lo que creas que necesitamos saber (alergias, tronas de bebé, etc.)</label>
							<textarea 
								className="form-control" 
								id="moreInfo" 
								rows="3" 
								name="moreInfo"
								placeholder="Escribe aquí..."
								value={formData.moreInfo}
        						onChange={handleChange}>
							</textarea>
						</div>
						<button type="submit" className="btn btn-primary">Confirmar asistencia</button>
						{ exito && <div className="alert alert-success mt-4" role="alert">
						Gracias por confirmar tu asistencia en nuestra boda, nos vemos el día 11 de mayo. Si tienes cualquier duda puedes contactarnos por teléfono (Paula: 622139317 y Juan: 663839100).
					 	</div> }
						 { error && <div className="alert alert-danger mt-4" role="alert">
						Hubo un error enviando el formulario, por favor contáctanos por teléfono para confirmar tu asistencia (Paula: 622139317 y Juan: 663839100) o inténtalo más tarde.
					 	</div> }
					</form>
				</div>
			</div>
		</div>
	)
}

export default Formulario
