import React from 'react'
   
const TextoHome = () => {
	return (
		<div className="TextoHome mt-5">
			<div className="row d-flex justify-content-center">
				<div className="col-md-6">
					<h3>¡Bienvenidos a la web de nuestra boda!</h3>
					<p>Estamos muy ilusionados de poder compartir este día tan especial contigo.</p>
					<p>En esta página web, podrás consultar toda la información sobre la celebración como, por ejemplo, el lugar, los horarios o las opciones de alojamiento. ¡También encontrarás un formulario para confirmar tu asistencia!</p>
					<p>Si tienes cualquier pregunta, no dudes en contactarnos. Nuestros teléfonos son: 663839100 (Juan) y 622139317 (Paula).</p>
					<p>¡Estamos deseando celebrar este día a tu lado! ❤️</p>
				</div>
			</div>
		</div>
	)
}

export default TextoHome
