import ImageGallery from "react-image-gallery";
import Uno from "../assets/img/nosotros-1.jpeg";
import Dos from "../assets/img/nosotros-2.jpg";
import Tres from "../assets/img/nosotros-3.jpg";

const images = [
	{
	  original: Dos,
	},
	{
	  original: Uno,
	},
	{
	  original: Tres,
	}
  ];

const Nosotros = () => {
	return (
		<div className="Place mt-5">
			<div className="row d-flex justify-content-center">
				<div className="col-md-6">
					<div className="mb-4">
						<ImageGallery 
							items={images} 
							showPlayButton={false}
							showThumbnails={false}
							showFullscreenButton={false}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Nosotros
