import React, { useState, useEffect } from 'react';

const Countdown = () => {
    const targetDate = new Date('May 11, 2024 18:00:00');
    const [timeLeft, setTimeLeft] = useState(getTimeDifference(targetDate));

    function getTimeDifference(endTime) {
        const now = new Date();
        const timeDiff = endTime - now;
        
        if(timeDiff <= 0) {
            return {
                days: 0,
                hours: 0,
                minutes: 0
            }
        }

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        return {
            days,
            hours,
            minutes
        };
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(getTimeDifference(targetDate));
        }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
		<div style={{ display: 'flex', justifyContent: 'center' }} className="mb-5">
			<div style={{ margin: '0 20px', textAlign: 'center' }}>
				<span style={{ fontSize: '2em' }}>{timeLeft.days}</span><br />
				<span>días</span>
			</div>
			<div style={{ margin: '0 20px', textAlign: 'center' }}>
				<span style={{ fontSize: '2em' }}>{timeLeft.hours}</span><br />
				<span>horas</span>
			</div>
			<div style={{ margin: '0 20px', textAlign: 'center' }}>
				<span style={{ fontSize: '2em' }}>{timeLeft.minutes}</span><br />
				<span>minutos</span>
			</div>
		</div>
	);
};

export default Countdown;