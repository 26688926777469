import React from 'react'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
import TextoHome from '../components/TextoHome'
import Place from '../components/Place'
import Icos from '../components/Icos'
import Formulario from '../components/Formulario'
import Booking from '../components/Booking'
import Gift from '../components/Gift'
import Footer from '../components/Footer'
import Nosotros from '../components/Nosotros'

const Home = () => {
	return (
		<div className="container">
			<Navbar />
			<Slider />
			<TextoHome />
			<Place />
			<Icos />
			<Formulario />
			<Nosotros />
			<Booking />
			<Gift />
			<Footer />
		</div>
	)
}

export default Home
